@import 'base.css';
@import 'vendors/index.css';
.page-header {
  font-size: 28px;
  color: #333333;
  letter-spacing: 0;
  line-height: 34px;
  font-weight: 500;
  border-bottom: 0;
}
/* body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
} */
/*
Connect Specific Overrides [if needed]
**/
/* @import 'closures.css';
@import 'connect.css';
@import 'tables.css';
@import 'tabs.css'; */
